@import url('font.css');

body {
  margin: 0;
}

/* 
    @RobotoBlack 
    @Roboto 
    @RobotoLight
*/

h1 {
  font-family: 'RobotoBold';
  opacity: 0.9;
  letter-spacing: -0.05em;
  color: #232b2b;
}

.roboto {
  font-family: 'Roboto';
}

.roboto-bold {
  font-family: 'RobotoBold';
}

.roboto-light {
  font-family: 'RobotoLight';
}

a {
  text-decoration: none;
  font-family: 'RobotoBold';
  color: #232222;
  font-size: 0.8em;
  color: white;
  letter-spacing: 2px;
}

.tile {
  width: 100%;
  height: 120%;
  background-image: linear-gradient(176deg, #3d9dea, #4a4eee);
  position: relative;
  border-radius: 40px;
  padding-top: 15%;
}

.tile:hover {
  cursor: pointer;
}

.duration {
  padding-left: 10%;
  padding-right: 10%;
}

.duration h3 {
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 500;
  font-size: 0.9em;
  text-transform: capitalize;
  color: white;
  font-family: 'Roboto';
  padding: none;
  margin: none !important;
}

.duration p {
  opacity: 0.8;
  color: white;
  padding-bottom: 10%;
}

.tile_bottom {
  background-image: linear-gradient(90deg, #414850, #131720);
  width: 100%;
  height: 40%;
  bottom: 0;
  position: absolute;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.tile_bottom_wrapper {
  padding-left: 10%;
  padding-right: 10%;
  color: white;
  font-family: 'RobotoLight';
}

.tile_bottom_wrapper .name {
  color: white;
  text-align: center;
  font-size: 26px;
  letter-spacing: -1.5px;
}

.tile_bottom_wrapper p {
  color: white;
  line-height: -100px;
  opacity: 0.8;
}

.tile_bottom_wrapper .featureIcon {
  width: 15px;
  height: 15px;
  background-image: linear-gradient(120deg, #343a4f, #0f1620);
  border-radius: 8px;
  display: inline-block;
  float: left;
  /* margin-right: 5px; */
}

.tile_bottom_wrapper .activity {
  /* margin-top: -10px; */
  float: left;
}

.first_aid {
  /* margin-top: -5%; */
  /* border: 1px solid black; */
  /* float: right; */
  /* margin-top: 105%; */
  border-radius: 50%;
  padding: 5% 5% 5% 5%;
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ed213a,
    #93291e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ed213a,
    #93291e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: absolute;
  z-index: 999;
  bottom: 0;
  right: 0;
}

.clear_filter {
  color: #989898;
  font-size: 0.9em;
}

.clear_filter:hover {
  text-decoration: underline;
  cursor: pointer;
}

#nav .ant-menu-title-content {
  color: white;
}
