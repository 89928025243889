@font-face {
    font-family: 'RobotoBlack';
    src: local('RobotoBlack'), 
        url('../assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'), 
        url('../assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoLight';
    src: url('../assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}
